@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

// basic colors:
$primary-color: #ff585f;
$white-color: #fff;
$black-color: #000;
$border-color: #d2d8dd;
$grey-text-color: #666;
$dark-color: #050708;
$gray-color: #6c757d;
$warning-color: #ff0000;
$success-color: #00e194;
$lighter-gray-color: #f4f4f4;
$video-overlay-bg-color: rgba(255, 255, 255, 0.8);

// datepicker colors:
$selected-date-background: #334048;
$datepicker-default-border-color: #aeaeae;
$datepicker-default-bright-color: #f0f0f0;
$datepicker-default-gray-color: #ccc;
$datepicker-default-light-gray-color: #b3b3b3;
$datepicker-default-beige-color: #e6e6e6;
$datepicker-default-magenta-color: #FF00FF;
$datepicker-default-green-color: #008000;
$datepicker-default-violet-color: #1d5d90;
$datepicker-default-light-green-color: #3dcc4a;
$datepicker-default-light-green-color: #3dcc4a;
$datepicker-default-blue-color: #2a87d0;

// font-sizes:

// font-sizes:
$xxs-font-size: 1rem;
$xs-font-size: 1.1rem;
$small-font-size: 1.2rem;
$small-base-font-size: 1.3rem;
$base-font-size: 1.4rem;
$base-m-font-size: 1.6rem;
$base-ml-font-size: 1.7rem;
$m-font-size: 1.8rem;
$ml-font-size: 2rem;
$l-font-size: 2.2rem;
$x-font-size: 2.4rem;
$xl-font-size: 2.6rem;
$xll-font-size: 2.8rem;
$xxl-font-size: 3.2rem;
$xxxl-font-size: 3.5rem;
$xxxxl-font-size: 4.5rem;

// breakpoints:
$breakpoint-xsmall: 480px;
$breakpoint-small: 768px;
$breakpoint-medium: 992px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1280px;
$breakpoint-xxlarge: 1400px;
$breakpoint-full: 1600px;

$form-font: 'Work Sans', sans-serif;
$form-font: 'Ubuntu', sans-serif;
@font-face {
  font-family: 'Gotham-Bold';
  src: url('./fonts/Gotham-Bold.eot');
  src: url('./fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Gotham-Bold.svg#Gotham-Bold') format('svg'),
  url('./fonts/Gotham-Bold.ttf') format('truetype'),
  url('./fonts/Gotham-Bold.woff') format('woff'),
  url('./fonts/Gotham-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham-Medium';
  src: url('./fonts/Gotham-Medium.eot');
  src: url('./fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Gotham-Medium.svg#Gotham-Medium') format('svg'),
  url('./fonts/Gotham-Medium.ttf') format('truetype'),
  url('./fonts/Gotham-Medium.woff') format('woff'),
  url('./fonts/Gotham-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham-Light';
  src: url('./fonts/Gotham-Light.eot');
  src: url('./fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Gotham-Light.svg#Gotham-Light') format('svg'),
  url('./fonts/Gotham-Light.ttf') format('truetype'),
  url('./fonts/Gotham-Light.woff') format('woff'),
  url('./fonts/Gotham-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

