@import "../../../../../../assets/styles/variables";

.modalContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  border-right: 0.1em solid #dbe0e6;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition: top 0.5s ease-in-out;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;

  .modalContent {
    background-color: $white-color;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2.5rem;

    .title {
      font-size: 1.6rem;
      font-weight: 500;
      color: $black-color;
      font-family: Poppins, sans-serif;
      margin-bottom: 2rem;
    }

    .deleteOptions {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.5rem;
        width: 5rem;
        border-radius: 0.8rem;
        font-size: $base-font-size;
        background-color: $white-color;
        color: #0f7ff3;
        text-transform: uppercase;

        &:hover {
          background-color: #0f7ff3;
          cursor: pointer;
          color: $white-color;
        }
      }

      .btnCancel {
        margin-right: 4rem;
      }
    }
  }
}
