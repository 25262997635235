.clearfix::before,
.clearfix::after {
  content: ' ';
  display: table;
}
.clearfix::after {
  clear: both;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.hidden {
  display: none !important;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease, visibility 0.35s ease;
  transition: height 0.35s ease, visibility 0.35s ease;
}
.sr-only {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  16.7%,
  50%,
  83.3% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }
  33.3%,
  66.7% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
}
@keyframes shake {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  16.7%,
  50%,
  83.3% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }
  33.3%,
  66.7% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
}
@-webkit-keyframes slideUpIn {
  0% {
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
  }
  100% {
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes slideUpIn {
  0% {
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
  }
  100% {
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes slideDownIn {
  0% {
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
  }
  100% {
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes slideDownIn {
  0% {
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
  }
  100% {
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes slideLeftIn {
  0% {
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@keyframes slideLeftIn {
  0% {
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@-webkit-keyframes slideRightIn {
  0% {
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@keyframes slideRightIn {
  0% {
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes bounceIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes bounceIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes bounceOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}
@keyframes bounceOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
}
@-webkit-keyframes slideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes slideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes slideOutLeft {
  from {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideOutLeft {
  from {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
}
@-webkit-keyframes slideInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes slideInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes slideOutRight {
  from {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
}
@keyframes slideOutRight {
  from {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
}
@-webkit-keyframes slideInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes slideInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes slideOutTop {
  from {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
}
@keyframes slideOutTop {
  from {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
}
@-webkit-keyframes slideInBottom {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes slideInBottom {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes slideOutBottom {
  from {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
}
@keyframes slideOutBottom {
  from {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
}
@-webkit-keyframes loaderSpin {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes loaderSpin {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes slideDown {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes slideDown {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes progress-active {
  0% {
    opacity: 0.1;
    width: 0;
  }
  20% {
    opacity: 0.5;
    width: 0;
  }
  100% {
    opacity: 0;
    width: 100%;
  }
}
@keyframes progress-active {
  0% {
    opacity: 0.1;
    width: 0;
  }
  20% {
    opacity: 0.5;
    width: 0;
  }
  100% {
    opacity: 0;
    width: 100%;
  }
}
@-webkit-keyframes progress-active-vertical {
  0% {
    opacity: 0.1;
    height: 0;
  }
  20% {
    opacity: 0.5;
    height: 0;
  }
  100% {
    opacity: 0;
    height: 100%;
  }
}
@keyframes progress-active-vertical {
  0% {
    opacity: 0.1;
    height: 0;
  }
  20% {
    opacity: 0.5;
    height: 0;
  }
  100% {
    opacity: 0;
    height: 100%;
  }
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@-webkit-keyframes sideNavFoldedText {
  0% {
    max-width: 200px;
    opacity: 0.8;
  }
  100% {
    max-width: 0;
    opacity: 0;
  }
}
@keyframes sideNavFoldedText {
  0% {
    max-width: 200px;
    opacity: 0.8;
  }
  100% {
    max-width: 0;
    opacity: 0;
  }
}
@-webkit-keyframes placeholder-active {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes placeholder-active {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@-webkit-keyframes moveLeftHalf {
  0% {
    -webkit-transform: none;
            transform: none;
  }
  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
@keyframes moveLeftHalf {
  0% {
    -webkit-transform: none;
            transform: none;
  }
  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
@-webkit-keyframes moveLeftHalf-vertical {
  0% {
    -webkit-transform: none;
            transform: none;
  }
  100% {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}
@keyframes moveLeftHalf-vertical {
  0% {
    -webkit-transform: none;
            transform: none;
  }
  100% {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}
@font-face {
  font-family: 'rsuite-icon-font';
  src: url('https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.ttf') format('truetype'), url('https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-family: Apple-System, Arial, Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif;
}
a {
  color: #1675e0;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #0a5dc2;
  text-decoration: underline;
}
a:active {
  color: #004299;
  text-decoration: underline;
}
a:focus {
  outline: 0;
}
img {
  vertical-align: middle;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #e5e5ea;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: bolder;
  color: inherit;
  margin: 0;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #c5c6c7;
}
h1 {
  font-size: 46px;
  line-height: 62px;
}
h2 {
  font-size: 36px;
  line-height: 50px;
}
h3 {
  font-size: 28px;
  line-height: 42px;
}
h4 {
  font-size: 22px;
  line-height: 34px;
}
h5 {
  font-size: 18px;
  line-height: 24px;
}
h6 {
  font-size: 16px;
  line-height: 22px;
}
p {
  margin: 0;
}
p + p {
  margin-top: 8px;
}
small {
  font-size: 85%;
}
mark {
  background-color: #fff9e6;
  padding: 0.2em;
  font-weight: bolder;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}
dl {
  margin-top: 0;
  margin-bottom: 10px;
}
dt,
dd {
  line-height: 1.42857143;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
  margin-bottom: 10px;
}
@font-face {
  font-family: Apple-System;
  src: local(-apple-system), local(BlinkMacSystemFont), local(system-ui);
}

.slide-out {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
          animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.slide-in {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.right.slide-in {
  -webkit-animation-name: slideInRight;
          animation-name: slideInRight;
}
.right.slide-out {
  -webkit-animation-name: slideOutRight;
          animation-name: slideOutRight;
}
.left.slide-in {
  -webkit-animation-name: slideInLeft;
          animation-name: slideInLeft;
}
.left.slide-out {
  -webkit-animation-name: slideOutLeft;
          animation-name: slideOutLeft;
}
.top.slide-in {
  -webkit-animation-name: slideInTop;
          animation-name: slideInTop;
}
.top.slide-out {
  -webkit-animation-name: slideOutTop;
          animation-name: slideOutTop;
}
.bottom.slide-in {
  -webkit-animation-name: slideInBottom;
          animation-name: slideInBottom;
}
.bottom.slide-out {
  -webkit-animation-name: slideOutBottom;
          animation-name: slideOutBottom;
}
.bounce-in {
  -webkit-animation-name: bounceIn;
          animation-name: bounceIn;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
          animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.bounce-out {
  -webkit-animation-name: bounceOut;
          animation-name: bounceOut;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
          animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}







.rs-calendar {
  background-color: #fff;
  padding-top: 12px;
  position: relative;
  min-height: 266px;
  overflow: hidden;
}
.rs-calendar-bordered .rs-calendar-table {
  border: 1px solid #f2f2f5;
  border-radius: 6px;
}
.rs-calendar-bordered .rs-calendar-table-row:not(:last-child) .rs-calendar-table-cell,
.rs-calendar-bordered .rs-calendar-table-header-row .rs-calendar-table-cell {
  border-bottom: 1px solid #f2f2f5;
}
.rs-calendar-bordered .rs-calendar-month-dropdown {
  border: 1px solid #e5e5ea;
  border-radius: 6px;
}
.rs-calendar-panel .rs-calendar-header {
  width: 100%;
  padding-bottom: 6px;
}
.rs-calendar-panel .rs-calendar-header::before,
.rs-calendar-panel .rs-calendar-header::after {
  content: ' ';
  display: table;
}
.rs-calendar-panel .rs-calendar-header::after {
  clear: both;
}
.rs-calendar-panel .rs-calendar-header::before,
.rs-calendar-panel .rs-calendar-header::after {
  content: ' ';
  display: table;
}
.rs-calendar-panel .rs-calendar-header::after {
  clear: both;
}
.rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
  float: right;
}
.rs-calendar-panel .rs-calendar-header-forward,
.rs-calendar-panel .rs-calendar-header-title,
.rs-calendar-panel .rs-calendar-header-backward {
  /* stylelint-disable */
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 6px;
  /* stylelint-enable */
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-ghost,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-ghost,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-ghost {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon {
  padding-left: 24px;
  height: 36px;
  position: relative;
}
.rs-btn-ghost.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon,
.rs-btn-ghost.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon,
.rs-btn-ghost.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon {
  padding-left: 22px;
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
  padding-left: 44px;
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 12px;
  padding-right: 44px;
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
  left: auto;
  right: 0;
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon > .rs-icon,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon > .rs-icon,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 36px;
  height: 36px;
  padding: 8px 0;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-btn-ghost.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon > .rs-icon,
.rs-btn-ghost.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon > .rs-icon,
.rs-btn-ghost.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon > .rs-icon {
  width: 34px;
  height: 34px;
  padding: 7px 0;
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon > .rs-icon > svg,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon > .rs-icon > svg,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon > .rs-icon > svg {
  height: 14px;
  vertical-align: middle;
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon-circle,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon-circle,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon-circle {
  width: 36px;
  padding: 8px;
}
.rs-calendar-panel .rs-calendar-header-forward,
.rs-calendar-panel .rs-calendar-header-backward {
  width: 31px;
  float: none !important;
}
.rs-calendar-panel.rs-calendar-show-month-dropdown .rs-calendar-header-forward,
.rs-calendar-panel.rs-calendar-show-month-dropdown .rs-calendar-header-backward {
  display: inline-block;
  visibility: hidden;
  pointer-events: none;
}
.rs-calendar-panel .rs-calendar-header-month-toolbar {
  width: auto !important;
}
.rs-calendar-panel.rs-calendar-show-month-dropdown .rs-calendar-header-month-toolbar {
  padding-left: 0;
  padding-right: 0;
}
.rs-calendar-panel .rs-calendar-show-month-dropdown {
  padding-left: 0;
  padding-right: 0;
}
.rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-cell-content {
  padding-top: 8px;
  padding-bottom: 8px;
}
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
  color: #fff;
  width: 20px;
  height: 20px;
  background-color: #3498ff;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border-color: transparent;
}
.rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: transparent;
  color: inherit;
  border: 1px solid #3498ff;
  padding: 4px;
}
.rs-calendar-panel .rs-calendar-table-cell-day {
  margin-top: 3px;
  display: block;
  text-align: center;
}
.rs-calendar-panel .rs-calendar-month-dropdown {
  margin-left: 12px;
  margin-right: 12px;
  top: 54px;
  width: calc(100% - 24px);
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-month-dropdown-scroll {
  height: 644px;
}
.rs-calendar-panel.rs-calendar-compact .rs-calendar-month-dropdown-scroll {
  height: 344px;
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
  width: 100%;
  height: 100px;
  overflow: hidden;
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-cell {
  vertical-align: top;
}
.rs-calendar-panel .rs-calendar-table-cell {
  padding: 0 1px;
  line-height: 0;
}
.rs-calendar-panel .rs-calendar-table-cell-content {
  width: 100%;
  border-radius: 0;
}
.rs-calendar-panel .rs-calendar-table-row:last-child :first-child .rs-calendar-table-cell-content {
  border-bottom-left-radius: 6px;
}
.rs-calendar-panel .rs-calendar-table-row:last-child :last-child .rs-calendar-table-cell-content {
  border-bottom-right-radius: 6px;
}
.rs-calendar-panel.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
  height: 50px;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-month-toolbar {
  padding-left: 24px;
  padding-right: 24px;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-backward,
.rs-calendar-show-month-dropdown .rs-calendar-header-forward {
  display: none;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-title-date {
  color: #1675e0;
  background: transparent;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-title-date.rs-calendar-header-error {
  color: #f44336;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-title-date.rs-calendar-header-error:focus,
.rs-calendar-show-month-dropdown .rs-calendar-header-title-date.rs-calendar-header-error:hover:active {
  color: #fff !important;
}
.rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
  color: #1675e0;
  background: transparent;
}
.rs-calendar-show-time-dropdown .rs-calendar-header-title-time.rs-calendar-header-error {
  color: #f44336;
}
.rs-calendar-show-time-dropdown .rs-calendar-header-title-time.rs-calendar-header-error:hover:active {
  color: #fff;
}
.rs-calendar-table-cell-selected,
.rs-calendar-table-cell-in-range {
  position: relative;
}
.rs-calendar-table-cell-selected::before,
.rs-calendar-table-cell-in-range::before {
  content: '';
  display: block;
  width: 100%;
  margin-top: 3px;
  height: 24px;
  position: absolute;
  z-index: 0;
  top: 0;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content {
  z-index: 1;
  position: relative;
}
.rs-calendar-table-cell-in-range::before {
  background-color: rgba(204, 233, 255, 0.5);
}
.rs-calendar-month-dropdown-scroll div:focus {
  outline: none;
}
.rs-calendar-header {
  width: 280px;
  padding-left: 12px;
  padding-right: 12px;
}
.rs-calendar-header::before,
.rs-calendar-header::after {
  content: ' ';
  display: table;
}
.rs-calendar-header::after {
  clear: both;
}
.rs-calendar-header::before,
.rs-calendar-header::after {
  content: ' ';
  display: table;
}
.rs-calendar-header::after {
  clear: both;
}
.rs-calendar-header-month-toolbar,
.rs-calendar-header-time-toolbar {
  display: inline-block;
}
.rs-calendar-header-month-toolbar {
  float: left;
}
.rs-calendar-header-time-toolbar {
  float: right;
}
.rs-calendar-header-title,
.rs-calendar-header-meridian,
.rs-calendar-header-backward,
.rs-calendar-header-forward {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* stylelint-disable */
  padding: 8px 12px;
  /* stylelint-enable */
  -webkit-transition: color 0.2s linear, background-color 0.3s linear;
  transition: color 0.2s linear, background-color 0.3s linear;
  color: #8e8e93;
  background-color: transparent;
  /* stylelint-disable */
  padding: 2px 8px;
  font-size: 12px;
  line-height: 1.66666667;
  border-radius: 6px;
  /* stylelint-enable */
  padding: 2px 2px;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-calendar-header-title.rs-btn-ghost,
.rs-calendar-header-meridian.rs-btn-ghost,
.rs-calendar-header-backward.rs-btn-ghost,
.rs-calendar-header-forward.rs-btn-ghost {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-calendar-header-title.rs-btn-icon,
.rs-calendar-header-meridian.rs-btn-icon,
.rs-calendar-header-backward.rs-btn-icon,
.rs-calendar-header-forward.rs-btn-icon {
  padding-left: 24px;
  height: 36px;
  position: relative;
}
.rs-btn-ghost.rs-calendar-header-title.rs-btn-icon,
.rs-btn-ghost.rs-calendar-header-meridian.rs-btn-icon,
.rs-btn-ghost.rs-calendar-header-backward.rs-btn-icon,
.rs-btn-ghost.rs-calendar-header-forward.rs-btn-icon {
  padding-left: 22px;
}
.rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
.rs-calendar-header-meridian.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
  padding-left: 44px;
}
.rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
.rs-calendar-header-meridian.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 12px;
  padding-right: 44px;
}
.rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
.rs-calendar-header-meridian.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
  left: auto;
  right: 0;
}
.rs-calendar-header-title.rs-btn-icon > .rs-icon,
.rs-calendar-header-meridian.rs-btn-icon > .rs-icon,
.rs-calendar-header-backward.rs-btn-icon > .rs-icon,
.rs-calendar-header-forward.rs-btn-icon > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 36px;
  height: 36px;
  padding: 8px 0;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-btn-ghost.rs-calendar-header-title.rs-btn-icon > .rs-icon,
.rs-btn-ghost.rs-calendar-header-meridian.rs-btn-icon > .rs-icon,
.rs-btn-ghost.rs-calendar-header-backward.rs-btn-icon > .rs-icon,
.rs-btn-ghost.rs-calendar-header-forward.rs-btn-icon > .rs-icon {
  width: 34px;
  height: 34px;
  padding: 7px 0;
}
.rs-calendar-header-title.rs-btn-icon > .rs-icon > svg,
.rs-calendar-header-meridian.rs-btn-icon > .rs-icon > svg,
.rs-calendar-header-backward.rs-btn-icon > .rs-icon > svg,
.rs-calendar-header-forward.rs-btn-icon > .rs-icon > svg {
  height: 14px;
  vertical-align: middle;
}
.rs-calendar-header-title.rs-btn-icon-circle,
.rs-calendar-header-meridian.rs-btn-icon-circle,
.rs-calendar-header-backward.rs-btn-icon-circle,
.rs-calendar-header-forward.rs-btn-icon-circle {
  width: 36px;
  padding: 8px;
}
.rs-calendar-header-title:hover,
.rs-calendar-header-meridian:hover,
.rs-calendar-header-backward:hover,
.rs-calendar-header-forward:hover,
.rs-calendar-header-title:focus,
.rs-calendar-header-meridian:focus,
.rs-calendar-header-backward:focus,
.rs-calendar-header-forward:focus {
  color: #575757;
  text-decoration: none;
}
.rs-calendar-header-title:active,
.rs-calendar-header-meridian:active,
.rs-calendar-header-backward:active,
.rs-calendar-header-forward:active,
.rs-calendar-header-title.rs-btn-active,
.rs-calendar-header-meridian.rs-btn-active,
.rs-calendar-header-backward.rs-btn-active,
.rs-calendar-header-forward.rs-btn-active {
  outline: 0;
  background-image: none;
}
.rs-calendar-header-title.rs-btn-loading:hover,
.rs-calendar-header-meridian.rs-btn-loading:hover,
.rs-calendar-header-backward.rs-btn-loading:hover,
.rs-calendar-header-forward.rs-btn-loading:hover,
.rs-calendar-header-title.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-meridian.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-backward.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-forward.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-title.rs-btn-loading:focus,
.rs-calendar-header-meridian.rs-btn-loading:focus,
.rs-calendar-header-backward.rs-btn-loading:focus,
.rs-calendar-header-forward.rs-btn-loading:focus,
.rs-calendar-header-title.rs-btn-loading:active,
.rs-calendar-header-meridian.rs-btn-loading:active,
.rs-calendar-header-backward.rs-btn-loading:active,
.rs-calendar-header-forward.rs-btn-loading:active,
.rs-calendar-header-title.rs-btn-loading:hover:active,
.rs-calendar-header-meridian.rs-btn-loading:hover:active,
.rs-calendar-header-backward.rs-btn-loading:hover:active,
.rs-calendar-header-forward.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-calendar-header-title:focus,
.rs-calendar-header-meridian:focus,
.rs-calendar-header-backward:focus,
.rs-calendar-header-forward:focus,
.rs-calendar-header-title.rs-btn-focus,
.rs-calendar-header-meridian.rs-btn-focus,
.rs-calendar-header-backward.rs-btn-focus,
.rs-calendar-header-forward.rs-btn-focus,
.rs-calendar-header-title:hover,
.rs-calendar-header-meridian:hover,
.rs-calendar-header-backward:hover,
.rs-calendar-header-forward:hover {
  color: #fff;
  background: #f7f7fa;
}
.rs-calendar-header-title:active,
.rs-calendar-header-meridian:active,
.rs-calendar-header-backward:active,
.rs-calendar-header-forward:active,
.rs-calendar-header-title.rs-btn-active,
.rs-calendar-header-meridian.rs-btn-active,
.rs-calendar-header-backward.rs-btn-active,
.rs-calendar-header-forward.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward {
  color: #fff;
  background-color: #b2b1f0;
  background-image: none;
}
.rs-calendar-header-title:active:hover,
.rs-calendar-header-meridian:active:hover,
.rs-calendar-header-backward:active:hover,
.rs-calendar-header-forward:active:hover,
.rs-calendar-header-title.rs-btn-active:hover,
.rs-calendar-header-meridian.rs-btn-active:hover,
.rs-calendar-header-backward.rs-btn-active:hover,
.rs-calendar-header-forward.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward:hover,
.rs-calendar-header-title:active:focus,
.rs-calendar-header-meridian:active:focus,
.rs-calendar-header-backward:active:focus,
.rs-calendar-header-forward:active:focus,
.rs-calendar-header-title.rs-btn-active:focus,
.rs-calendar-header-meridian.rs-btn-active:focus,
.rs-calendar-header-backward.rs-btn-active:focus,
.rs-calendar-header-forward.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward:focus,
.rs-calendar-header-title:active.focus,
.rs-calendar-header-meridian:active.focus,
.rs-calendar-header-backward:active.focus,
.rs-calendar-header-forward:active.focus,
.rs-calendar-header-title.rs-btn-active.focus,
.rs-calendar-header-meridian.rs-btn-active.focus,
.rs-calendar-header-backward.rs-btn-active.focus,
.rs-calendar-header-forward.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.focus {
  color: #fff;
  background-color: #b2b1f0;
}
.rs-calendar-header-title.rs-btn-disabled,
.rs-calendar-header-meridian.rs-btn-disabled,
.rs-calendar-header-backward.rs-btn-disabled,
.rs-calendar-header-forward.rs-btn-disabled,
.rs-calendar-header-title.rs-btn-disabled:hover,
.rs-calendar-header-meridian.rs-btn-disabled:hover,
.rs-calendar-header-backward.rs-btn-disabled:hover,
.rs-calendar-header-forward.rs-btn-disabled:hover,
.rs-calendar-header-title.rs-btn-disabled:focus,
.rs-calendar-header-meridian.rs-btn-disabled:focus,
.rs-calendar-header-backward.rs-btn-disabled:focus,
.rs-calendar-header-forward.rs-btn-disabled:focus,
.rs-calendar-header-title.rs-btn-disabled.focus,
.rs-calendar-header-meridian.rs-btn-disabled.focus,
.rs-calendar-header-backward.rs-btn-disabled.focus,
.rs-calendar-header-forward.rs-btn-disabled.focus,
.rs-calendar-header-title.rs-btn-disabled:active,
.rs-calendar-header-meridian.rs-btn-disabled:active,
.rs-calendar-header-backward.rs-btn-disabled:active,
.rs-calendar-header-forward.rs-btn-disabled:active,
.rs-calendar-header-title.rs-btn-disabled:hover:active,
.rs-calendar-header-meridian.rs-btn-disabled:hover:active,
.rs-calendar-header-backward.rs-btn-disabled:hover:active,
.rs-calendar-header-forward.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-calendar-header-title.rs-btn-loading:hover,
.rs-calendar-header-meridian.rs-btn-loading:hover,
.rs-calendar-header-backward.rs-btn-loading:hover,
.rs-calendar-header-forward.rs-btn-loading:hover,
.rs-calendar-header-title.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-meridian.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-backward.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-forward.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-title.rs-btn-loading:focus,
.rs-calendar-header-meridian.rs-btn-loading:focus,
.rs-calendar-header-backward.rs-btn-loading:focus,
.rs-calendar-header-forward.rs-btn-loading:focus,
.rs-calendar-header-title.rs-btn-loading:active,
.rs-calendar-header-meridian.rs-btn-loading:active,
.rs-calendar-header-backward.rs-btn-loading:active,
.rs-calendar-header-forward.rs-btn-loading:active,
.rs-calendar-header-title.rs-btn-loading:hover:active,
.rs-calendar-header-meridian.rs-btn-loading:hover:active,
.rs-calendar-header-backward.rs-btn-loading:hover:active,
.rs-calendar-header-forward.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-calendar-header-title.rs-btn-disabled,
.rs-calendar-header-meridian.rs-btn-disabled,
.rs-calendar-header-backward.rs-btn-disabled,
.rs-calendar-header-forward.rs-btn-disabled,
.rs-calendar-header-title.rs-btn-disabled:hover,
.rs-calendar-header-meridian.rs-btn-disabled:hover,
.rs-calendar-header-backward.rs-btn-disabled:hover,
.rs-calendar-header-forward.rs-btn-disabled:hover,
.rs-calendar-header-title.rs-btn-disabled:focus,
.rs-calendar-header-meridian.rs-btn-disabled:focus,
.rs-calendar-header-backward.rs-btn-disabled:focus,
.rs-calendar-header-forward.rs-btn-disabled:focus,
.rs-calendar-header-title.rs-btn-disabled:active,
.rs-calendar-header-meridian.rs-btn-disabled:active,
.rs-calendar-header-backward.rs-btn-disabled:active,
.rs-calendar-header-forward.rs-btn-disabled:active {
  opacity: 1;
}
.rs-calendar-header-title:focus,
.rs-calendar-header-meridian:focus,
.rs-calendar-header-backward:focus,
.rs-calendar-header-forward:focus,
.rs-calendar-header-title.rs-btn-focus,
.rs-calendar-header-meridian.rs-btn-focus,
.rs-calendar-header-backward.rs-btn-focus,
.rs-calendar-header-forward.rs-btn-focus,
.rs-calendar-header-title:hover,
.rs-calendar-header-meridian:hover,
.rs-calendar-header-backward:hover,
.rs-calendar-header-forward:hover {
  color: #575757;
}
.rs-calendar-header-title:active,
.rs-calendar-header-meridian:active,
.rs-calendar-header-backward:active,
.rs-calendar-header-forward:active,
.rs-calendar-header-title.rs-btn-active,
.rs-calendar-header-meridian.rs-btn-active,
.rs-calendar-header-backward.rs-btn-active,
.rs-calendar-header-forward.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward {
  color: #272c36;
  background-color: #e5e5ea;
  background-image: none;
}
.rs-calendar-header-title:active:hover,
.rs-calendar-header-meridian:active:hover,
.rs-calendar-header-backward:active:hover,
.rs-calendar-header-forward:active:hover,
.rs-calendar-header-title.rs-btn-active:hover,
.rs-calendar-header-meridian.rs-btn-active:hover,
.rs-calendar-header-backward.rs-btn-active:hover,
.rs-calendar-header-forward.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward:hover,
.rs-calendar-header-title:active:focus,
.rs-calendar-header-meridian:active:focus,
.rs-calendar-header-backward:active:focus,
.rs-calendar-header-forward:active:focus,
.rs-calendar-header-title.rs-btn-active:focus,
.rs-calendar-header-meridian.rs-btn-active:focus,
.rs-calendar-header-backward.rs-btn-active:focus,
.rs-calendar-header-forward.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward:focus,
.rs-calendar-header-title:active.focus,
.rs-calendar-header-meridian:active.focus,
.rs-calendar-header-backward:active.focus,
.rs-calendar-header-forward:active.focus,
.rs-calendar-header-title.rs-btn-active.focus,
.rs-calendar-header-meridian.rs-btn-active.focus,
.rs-calendar-header-backward.rs-btn-active.focus,
.rs-calendar-header-forward.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.focus {
  color: #272c36;
  background-color: #e5e5ea;
}
.rs-calendar-header-title.rs-btn-red,
.rs-calendar-header-meridian.rs-btn-red,
.rs-calendar-header-backward.rs-btn-red,
.rs-calendar-header-forward.rs-btn-red {
  color: #8e8e93;
  background-color: transparent;
}
.rs-calendar-header-title.rs-btn-blue,
.rs-calendar-header-meridian.rs-btn-blue,
.rs-calendar-header-backward.rs-btn-blue,
.rs-calendar-header-forward.rs-btn-blue {
  color: #8e8e93;
  background-color: transparent;
}
.rs-calendar-header-title.rs-btn-blue.rs-btn-loading:hover,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-loading:hover,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-loading:hover,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-loading:hover,
.rs-calendar-header-title.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-title.rs-btn-blue.rs-btn-loading:focus,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-loading:focus,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-loading:focus,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-loading:focus,
.rs-calendar-header-title.rs-btn-blue.rs-btn-loading:active,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-loading:active,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-loading:active,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-loading:active,
.rs-calendar-header-title.rs-btn-blue.rs-btn-loading:hover:active,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-loading:hover:active,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-loading:hover:active,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-calendar-header-title.rs-btn-blue:focus,
.rs-calendar-header-meridian.rs-btn-blue:focus,
.rs-calendar-header-backward.rs-btn-blue:focus,
.rs-calendar-header-forward.rs-btn-blue:focus,
.rs-calendar-header-title.rs-btn-blue.rs-btn-focus,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-focus,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-focus,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-focus,
.rs-calendar-header-title.rs-btn-blue:hover,
.rs-calendar-header-meridian.rs-btn-blue:hover,
.rs-calendar-header-backward.rs-btn-blue:hover,
.rs-calendar-header-forward.rs-btn-blue:hover {
  color: #fff;
  background: #2196f3;
}
.rs-calendar-header-title.rs-btn-blue:active,
.rs-calendar-header-meridian.rs-btn-blue:active,
.rs-calendar-header-backward.rs-btn-blue:active,
.rs-calendar-header-forward.rs-btn-blue:active,
.rs-calendar-header-title.rs-btn-blue.rs-btn-active,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-active,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-active,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-blue,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-blue,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-blue,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-blue {
  color: #fff;
  background-color: #1787e8;
  background-image: none;
}
.rs-calendar-header-title.rs-btn-blue:active:hover,
.rs-calendar-header-meridian.rs-btn-blue:active:hover,
.rs-calendar-header-backward.rs-btn-blue:active:hover,
.rs-calendar-header-forward.rs-btn-blue:active:hover,
.rs-calendar-header-title.rs-btn-blue.rs-btn-active:hover,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-active:hover,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-active:hover,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-blue:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-blue:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-blue:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-blue:hover,
.rs-calendar-header-title.rs-btn-blue:active:focus,
.rs-calendar-header-meridian.rs-btn-blue:active:focus,
.rs-calendar-header-backward.rs-btn-blue:active:focus,
.rs-calendar-header-forward.rs-btn-blue:active:focus,
.rs-calendar-header-title.rs-btn-blue.rs-btn-active:focus,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-active:focus,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-active:focus,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-blue:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-blue:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-blue:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-blue:focus,
.rs-calendar-header-title.rs-btn-blue:active.focus,
.rs-calendar-header-meridian.rs-btn-blue:active.focus,
.rs-calendar-header-backward.rs-btn-blue:active.focus,
.rs-calendar-header-forward.rs-btn-blue:active.focus,
.rs-calendar-header-title.rs-btn-blue.rs-btn-active.focus,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-active.focus,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-active.focus,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-blue.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-blue.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-blue.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-blue.focus {
  color: #fff;
  background-color: #1787e8;
}
.rs-calendar-header-title.rs-btn-blue.rs-btn-disabled,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-disabled,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-disabled,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-disabled,
.rs-calendar-header-title.rs-btn-blue.rs-btn-disabled:hover,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-disabled:hover,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-disabled:hover,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-disabled:hover,
.rs-calendar-header-title.rs-btn-blue.rs-btn-disabled:focus,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-disabled:focus,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-disabled:focus,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-disabled:focus,
.rs-calendar-header-title.rs-btn-blue.rs-btn-disabled.focus,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-disabled.focus,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-disabled.focus,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-disabled.focus,
.rs-calendar-header-title.rs-btn-blue.rs-btn-disabled:active,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-disabled:active,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-disabled:active,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-disabled:active,
.rs-calendar-header-title.rs-btn-blue.rs-btn-disabled:hover:active,
.rs-calendar-header-meridian.rs-btn-blue.rs-btn-disabled:hover:active,
.rs-calendar-header-backward.rs-btn-blue.rs-btn-disabled:hover:active,
.rs-calendar-header-forward.rs-btn-blue.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-calendar-header-title.rs-btn-violet,
.rs-calendar-header-meridian.rs-btn-violet,
.rs-calendar-header-backward.rs-btn-violet,
.rs-calendar-header-forward.rs-btn-violet {
  color: #8e8e93;
  background-color: transparent;
}
.rs-calendar-header-title {
  margin: 0 0;
}
.rs-calendar-header-meridian {
  line-height: 12px;
  border: 1px solid #e5e5ea;
  font-size: 12px;
  padding: 2px 12px 2px 2px;
  margin-left: 4px;
}
.rs-calendar-header-meridian::after {
  -webkit-transform: scale(0.7, 0.9);
          transform: scale(0.7, 0.9);
  position: absolute;
  font-family: 'rsuite-icon-font';
  content: '\f0dc';
}
.rs-calendar-header-error {
  color: #8e8e93;
  background-color: transparent;
  color: #f44336;
}
.rs-calendar-header-error.rs-btn-loading:hover,
.rs-calendar-header-error.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-error.rs-btn-loading:focus,
.rs-calendar-header-error.rs-btn-loading:active,
.rs-calendar-header-error.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-calendar-header-error:focus,
.rs-calendar-header-error.rs-btn-focus,
.rs-calendar-header-error:hover {
  color: #fff;
  background: #f44336;
}
.rs-calendar-header-error:active,
.rs-calendar-header-error.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-error {
  color: #fff;
  background-color: #eb3626;
  background-image: none;
}
.rs-calendar-header-error:active:hover,
.rs-calendar-header-error.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-error:hover,
.rs-calendar-header-error:active:focus,
.rs-calendar-header-error.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-error:focus,
.rs-calendar-header-error:active.focus,
.rs-calendar-header-error.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-error.focus {
  color: #fff;
  background-color: #eb3626;
}
.rs-calendar-header-error.rs-btn-disabled,
.rs-calendar-header-error.rs-btn-disabled:hover,
.rs-calendar-header-error.rs-btn-disabled:focus,
.rs-calendar-header-error.rs-btn-disabled.focus,
.rs-calendar-header-error.rs-btn-disabled:active,
.rs-calendar-header-error.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-calendar-header-btn-disabled,
.rs-calendar-header-btn-disabled:hover,
.rs-calendar-header-btn-disabled:hover:focus {
  cursor: not-allowed;
  opacity: 0.3;
  background: none;
}
.rs-calendar-header-btn-disabled::after {
  display: none !important;
}
.rs-calendar-header-backward,
.rs-calendar-header-forward {
  font-size: 0;
  width: 24px;
}
.rs-calendar-header-backward::before,
.rs-calendar-header-forward::before {
  font-family: 'rsuite-icon-font';
  font-style: normal;
  font-size: 14px;
}
.rs-calendar-header-backward::before {
  content: '\ea0a';
}
.rs-calendar-header-forward::before {
  content: '\ea0c';
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
  display: block;
  text-align: center;
  width: 100%;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::before,
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  content: ' ';
  display: table;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  clear: both;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::before,
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  content: ' ';
  display: table;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  clear: both;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward {
  float: left;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward {
  float: right;
}
.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) {
  margin: 0 auto;
  text-align: center;
}
.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) .rs-calendar-header-time-toolbar {
  float: none;
  display: inline-block;
}
.rs-calendar-header-has-month.rs-calendar-header-has-time {
  margin: 0 auto;
}
/* rtl:begin:ignore */
/* stylelint-disable-next-line */
[dir='rtl'] .rs-calendar-header-backward,
[dir='rtl'] .rs-calendar-header-forward {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
/* rtl:end:ignore */
.rs-calendar-view {
  padding: 4px 12px 12px;
}
.rs-calendar-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.rs-calendar-table-row {
  display: table-row;
}
.rs-calendar-table-row:nth-child(2) .rs-calendar-table-cell-week-number {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.rs-calendar-table-row:last-child .rs-calendar-table-cell-week-number {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.rs-calendar-table-cell {
  display: table-cell;
  width: 1%;
  padding: 1px;
  text-align: center;
  vertical-align: middle;
}
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover {
  color: #c5c6c7;
}
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover {
  background: none;
  text-decoration: line-through;
  cursor: not-allowed;
}
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
  color: #ffffff;
  border-color: #3498ff;
  background-color: #3498ff;
}
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  opacity: 0.3;
}
.rs-calendar-table-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;
}
.rs-calendar-table-cell-content:hover {
  background-color: #f2faff;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border: 1px solid #1675e0;
  padding: 4px;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #ffffff;
  border-color: #3498ff;
  background-color: #3498ff;
}
.rs-calendar-table-header-row .rs-calendar-table-cell-content {
  color: #8e8e93;
  font-size: 12px;
  line-height: 1.66666667;
  padding-top: 2px;
  padding-bottom: 2px;
}
.rs-calendar-table-header-row .rs-calendar-table-cell-content,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:focus,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover:focus {
  background: none;
  cursor: auto;
}
.rs-calendar-table-cell-week-number {
  display: table-cell;
  width: 1%;
  padding: 1px;
  text-align: center;
  vertical-align: middle;
  color: #8e8e93;
  background: #f7f7fa;
  font-size: 12px;
}
.rs-calendar-month-dropdown {
  display: none;
  position: absolute;
  top: 40px;
  border-top: 1px solid #e5e5ea;
  margin-top: 4px;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  z-index: 1;
}
.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown {
  display: block;
}
.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown-content {
  -webkit-animation: 0.3s linear slideDown;
          animation: 0.3s linear slideDown;
}
.rs-calendar-month-dropdown-scroll {
  height: 230px;
}
.rs-calendar-month-dropdown-row {
  position: relative;
  padding-left: 52px;
  padding-right: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.rs-calendar-month-dropdown-row:not(:last-child) {
  border-bottom: 1px dotted #e5e5ea;
}
.rs-calendar-month-dropdown-year {
  position: absolute;
  top: calc(50% - 0.5em);
  left: 12px;
}
.rs-calendar-month-dropdown-year-active {
  color: #1675e0;
}
.rs-calendar-month-dropdown-list {
  display: block;
}
.rs-calendar-month-dropdown-list::before,
.rs-calendar-month-dropdown-list::after {
  content: ' ';
  display: table;
}
.rs-calendar-month-dropdown-list::after {
  clear: both;
}
.rs-calendar-month-dropdown-list::before,
.rs-calendar-month-dropdown-list::after {
  content: ' ';
  display: table;
}
.rs-calendar-month-dropdown-list::after {
  clear: both;
}
.rs-calendar-month-dropdown-cell {
  display: inline-block;
  float: left;
  width: calc((100% - 1px * 12 ) / 6);
  margin: 1px;
  text-align: center;
  vertical-align: middle;
  width: calc((100% - 1px * 12 - 1px) / 6)\9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-calendar-month-dropdown-cell {
    width: calc((100% - 1px * 12 - 1px) / 6);
  }
}
@supports (-ms-ime-align: auto) {
  .rs-calendar-month-dropdown-cell {
    width: calc((100% - 1px * 12 - 1px) / 6);
  }
}
.rs-calendar-month-dropdown-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;
}
.rs-calendar-month-dropdown-cell-content:hover {
  background-color: #f2faff;
}
.rs-calendar-month-dropdown-cell:not(.rs-calendar-month-dropdown-cell-active).disabled .rs-calendar-month-dropdown-cell-content {
  color: #c5c6c7;
  background: none;
  text-decoration: line-through;
  cursor: not-allowed;
}
.rs-calendar-month-dropdown-cell-active.disabled .rs-calendar-month-dropdown-cell-content {
  opacity: 0.3;
  cursor: not-allowed;
}
.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  color: #ffffff;
  border-color: #3498ff;
  background-color: #3498ff;
}
.rs-calendar-time-dropdown {
  display: none;
  position: absolute;
  top: 40px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  z-index: 1;
}
.rs-calendar-show-time-dropdown .rs-calendar-time-dropdown {
  display: block;
}
.rs-calendar-show-time-dropdown .rs-calendar-time-dropdown-content {
  -webkit-animation: 0.3s linear slideDown;
          animation: 0.3s linear slideDown;
}
.rs-calendar-time-dropdown-row {
  display: table;
  width: 100%;
}
.rs-calendar-time-dropdown-column {
  display: table-cell;
  width: 1%;
}
.rs-calendar-time-dropdown-column > ul,
.rs-calendar-time-dropdown-column > ul > li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rs-calendar-time-dropdown-column > ul {
  height: 230px;
  overflow-y: auto;
  padding-bottom: 200px;
}
.rs-calendar-time-dropdown-column-title {
  width: 100%;
  background-color: #f7f7fa;
  color: #8e8e93;
  text-align: center;
  font-size: 12px;
  line-height: 1.66666667;
  padding-top: 2px;
  padding-bottom: 2px;
}
.rs-calendar-time-dropdown-cell {
  font-size: 14px;
  line-height: 20px;
  padding: 5px;
  display: block;
  color: #575757;
  text-align: center;
  cursor: pointer;
  -webkit-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}
.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell,
.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell:hover {
  background-color: #3498ff;
  color: #fff;
}
.rs-calendar-time-dropdown-cell,
.rs-calendar-time-dropdown-cell:hover,
.rs-calendar-time-dropdown-cell:focus {
  text-decoration: none;
}
.rs-calendar-time-dropdown-cell:hover {
  color: #575757;
  background-color: #f2faff;
}
.rs-calendar-time-dropdown-cell-disabled,
.rs-calendar-time-dropdown-cell-disabled:hover,
.rs-calendar-time-dropdown-cell-disabled:focus {
  color: #c5c6c7;
  background: none;
  cursor: not-allowed;
  text-decoration: line-through;
}
.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell-disabled.rs-calendar-time-dropdown-cell {
  opacity: 0.3;
  cursor: not-allowed;
}

.rs-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-size: 12px;
    opacity: 0;
    line-height: 1.66666667;
    text-transform: uppercase;
}
.rs-tooltip.fade {
    -webkit-transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
}
.rs-tooltip.in {
    opacity: 1;
    -webkit-transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
}
.rs-tooltip.fade.in.placement-top {
    top: 465px;
}
.rs-tooltip .rs-tooltip-inner {
    max-width: 250px;
    padding: 2px 10px;
    color: #fff;
    text-align: center;
    background-color: #272c36;
    /*border-radius: 4px;*/
    overflow-wrap: break-word;
}
.rs-tooltip .rs-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
.rs-tooltip[class*='placement-top'] {
    margin-top: -6px;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}
.rs-tooltip[class*='placement-top'].in {
    -webkit-transform: translate(0, -2px);
    transform: translate(0, -2px);
}
.rs-tooltip[class*='placement-top'] .rs-tooltip-arrow {
    bottom: -6px;
    margin-left: -6px;
    border-width: 6px 6px 0;
    border-top-color: #272c36;
}
@supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-top'] .rs-tooltip-arrow {
        bottom: -5px;
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-top'] .rs-tooltip-arrow {
        bottom: -5px;
    }
}
.rs-tooltip[class*='placement-bottom'] {
    margin-top: 6px;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}
.rs-tooltip[class*='placement-bottom'].in {
    -webkit-transform: translate(0, 2px);
    transform: translate(0, 2px);
}
.rs-tooltip[class*='placement-bottom'] .rs-tooltip-arrow {
    top: -6px;
    margin-left: -6px;
    border-width: 0 6px 6px;
    border-bottom-color: #272c36;
}
@supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-bottom'] .rs-tooltip-arrow {
        top: -5px;
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-bottom'] .rs-tooltip-arrow {
        top: -5px;
    }
}
/* rtl:begin:ignore */
.rs-tooltip[class*='placement-right'] {
    margin-left: 6px;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}
.rs-tooltip[class*='placement-right'].in {
    -webkit-transform: translate(2px, 0);
    transform: translate(2px, 0);
}
.rs-tooltip[class*='placement-right'] .rs-tooltip-arrow {
    left: -6px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
    border-right-color: #272c36;
}
@supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-right'] .rs-tooltip-arrow {
        left: -5px;
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-right'] .rs-tooltip-arrow {
        left: -5px;
    }
}
.rs-tooltip[class*='placement-left'] {
    margin-left: -6px;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}
.rs-tooltip[class*='placement-left'].in {
    -webkit-transform: translate(-2px, 0);
    transform: translate(-2px, 0);
}
.rs-tooltip[class*='placement-left'] .rs-tooltip-arrow {
    right: -6px;
    margin-top: -6px;
    border-width: 6px 0 6px 6px;
    border-left-color: #272c36;
}
@supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-left'] .rs-tooltip-arrow {
        right: -5px;
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-left'] .rs-tooltip-arrow {
        right: -5px;
    }
}
/* rtl:end:ignore */
.rs-tooltip.placement-bottom .rs-tooltip-arrow,
.rs-tooltip.placement-top .rs-tooltip-arrow {
    left: 50%;
}
.rs-tooltip.placement-bottom-start .rs-tooltip-arrow,
.rs-tooltip.placement-top-start .rs-tooltip-arrow {
    left: 10px;
}
.rs-tooltip.placement-bottom-end .rs-tooltip-arrow,
.rs-tooltip.placement-top-end .rs-tooltip-arrow {
    right: 4px;
}
.rs-tooltip.placement-right .rs-tooltip-arrow,
.rs-tooltip.placement-left .rs-tooltip-arrow {
    top: 50%;
}
.rs-tooltip.placement-right-start .rs-tooltip-arrow,
.rs-tooltip.placement-left-start .rs-tooltip-arrow {
    top: 10px;
}
.rs-tooltip.placement-right-end .rs-tooltip-arrow,
.rs-tooltip.placement-left-end .rs-tooltip-arrow {
    bottom: 4px;
}
